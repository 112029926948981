import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/Page/Home.vue";

const routes = [
  {
    path: "/home",
    name: "home",
    component: HomeView,
  },

  // Customer Profile

  {
    path: "/register-user-list",
    name: "RegisterUserList",
    component: () => import("../views/CustomerProfile/RegisterdUsers.vue"),
  },

  {
    path: "/profile/:id",
    name: "Profile",
    component: () => import("../views/CustomerProfile/Profile.vue"),
  },
  {
    path: "/business-information/:id",
    name: "BusinessInformation",
    component: () => import("../views/CustomerProfile/BusinessInfo.vue"),
  },
  {
    path: "/company-information/:id",
    name: "CompanyInformation",
    component: () => import("../views/CustomerProfile/CompanyInfo.vue"),
  },

  {
    path: "/oprational-information/:id",
    name: "OprationalInformation",
    component: () =>
      import("../views/CustomerProfile/OprationalInformation.vue"),
  },

  // Container Section (add, search, edit , list)
  {
    path: "/add-new-container",
    name: "AddNewContainer",
    component: () => import("../views/Page/Container/AddNewContainer.vue"),
  },
  {
    path: "/edit-container/:id",
    name: "EditContainer",
    component: () => import("../views/Page/Container/EditContainer.vue"),
  },
  {
    path: "/container-search",
    name: "SearchContainer",
    component: () => import("../views/Page/Container/SearchContainer.vue"),
  },
  {
    path: "/containers",
    name: "Containers",
    component: () => import("../views/Page/Container/Containers.vue"),
  },

  {
    path: "/make-booking-container/:id",
    name: "MakeBookingContainer",
    component: () => import("../views/Page/Container/MakeBookingContainer.vue"),
  },

  {
    path: "/confirme-booking",
    name: "ConfirmBooking",
    component: () => import("../views/Page/Container/ConfirmBooking.vue"),
  },

  {
    path: "/thank-you-booking-page",
    name: "BookingThankYou",
    component: () =>
      import("../views/Page/Container/Thankyou/BookingThankYou.vue"),
  },

  {
    path: "/active-containers",
    name: "ActiveContainers",
    component: () => import("../views/Page/Container/ActiveContainers.vue"),
  },

  // Notification Section
  {
    path: "/notification-list",
    name: "Notifications",
    component: () => import("../views/Page/Notifications/Notification.vue"),
  },

  // History Section
  {
    path: "/history",
    name: "History",
    component: () => import("../views/Page/History/History.vue"),
  },
  // Stats Report Section
  {
    path: "/stats-report",
    name: "StatsReport",
    component: () => import("../views/Page/StatsReport/StatsReport.vue"),
  },

  // Payment Section
  {
    path: "/payment-pending",
    name: "PaymentPending",
    component: () => import("../views/Page/Payment/PaymentPending.vue"),
  },

  // Container Under Booking Section
  {
    path: "/container-under-booking",
    name: "ContainerUnderBooking",
    component: () =>
      import("../views/ContainerUnderBooking/ContainerUnderBooking.vue"),
  },

  {
    path: "/booking-request/:id",
    name: "BookingRequest",
    component: () =>
      import("../views/ContainerUnderBooking/BookingRequest.vue"),
  },

  {
    path: "/send-photos/:id",
    name: "SendPhotos",
    component: () => import("../views/ContainerUnderBooking/SendPhotos.vue"),
  },

  {
    path: "/linking-request/:id",
    name: "LinkingRequest",
    component: () =>
      import("../views/ContainerUnderBooking/LinkingRequest.vue"),
  },
  {
    path: "/delinking-request/:id",
    name: "DeLinkingRequest",
    component: () =>
      import("../views/ContainerUnderBooking/DeLinkingRequest.vue"),
  },
  {
    path: "/linking-payment/:id",
    name: "LinkingPayment",
    component: () =>
      import("../views/ContainerUnderBooking/LinkingPayment.vue"),
  },

  {
    path: "/start-moving-towords-exporter/:id",
    name: "MoveForward",
    component: () => import("../views/ContainerUnderBooking/MoveForward.vue"),
  },

  {
    path: "/party-details/:id",
    name: "PartyDetails",
    component: () => import("../views/ContainerUnderBooking/PartyDetails.vue"),
  },

  {
    path: "/container-status/:id",
    name: "Containerstatus",
    component: () =>
      import("../views/ContainerUnderBooking/Containerstatus.vue"),
  },

  {
    path: "/upload-documents/:id",
    name: "UploadDoccumnets",
    component: () =>
      import("../views/ContainerUnderBooking/UploadDoccumnets.vue"),
  },

  {
    path: "/trip-close/:id",
    name: "TripClose",
    component: () => import("../views/ContainerUnderBooking/TripClose.vue"),
  },

  {
    path: "/reject-or-cancel/:id",
    name: "RejectedOrCancelled",
    component: () =>
      import("../views/ContainerUnderBooking/RejectedOrCancelled.vue"),
  },
  // Re use Container

  {
    path: "/reuse-container/add-details",
    name: "/AddDetails",
    component: () => import("../views/Page/ReuseContainer/AddDetails.vue"),
  },

  {
    path: "/reuse-container/edit-details/:id",
    name: "/EditDetails",
    component: () => import("../views/Page/ReuseContainer/EditDetails.vue"),
  },

  {
    path: "/reuse-container/payment-edit-page/:id",
    name: "/PaymentEditPage",
    component: () => import("../views/Page/ReuseContainer/PaymentEditPage.vue"),
  },

  {
    path: "/reuse-container/linking-request",
    name: "/ReuseContainerLinkingRequest",
    component: () => import("../views/Page/ReuseContainer/LinkingRequest.vue"),
  },

  {
    path: "/reuse-container/linking-payments",
    name: "/ReuseLinkingPaymnets",
    component: () => import("../views/Page/ReuseContainer/LinkingPaymnets.vue"),
  },

  {
    path: "/reuse-container/history",
    name: "/ReuseHistory",
    component: () => import("../views/Page/ReuseContainer/History.vue"),
  },

  {
    path: "/reuse-container/delinking-request-list",
    name: "/DelinkingAdmin",
    component: () => import("../views/Page/ReuseContainer/DeLinking.vue"),
  },

  {
    path: "/reuse-container/delinking-request-history",
    name: "/DeLinkingHistory",
    component: () =>
      import("../views/Page/ReuseContainer/DeLinkingHistory.vue"),
  },

  // Master Section
  {
    path: "/master/commodity-list",
    name: "CommodityList",
    component: () => import("../views/Master/Commodity.vue"),
  },

  {
    path: "/master/axle-list",
    name: "AxleList",
    component: () => import("../views/Master/Axle.vue"),
  },

  {
    path: "/taluka-list",
    name: "TalukaList",
    component: () => import("../views/Master/Taluka.vue"),
  },
  {
    path: "/port",
    name: "Port",
    component: () => import("../views/Master/Ports.vue"),
  },

  {
    path: "/empty-container-list",
    name: "EmptyContainer",
    component: () => import("../views/Master/EmptyContainer.vue"),
  },
  {
    path: "/ocian-carrier",
    name: "OcianCarrier",
    component: () => import("../views/Master/OcianCarrier.vue"),
  },

  {
    path: "/shiping-line",
    name: "ShipingLine",
    component: () => import("../views/Master/ShipingLine.vue"),
  },
  {
    path: "/gujrate-division",
    name: "GujrateDivision",
    component: () => import("../views/Master/GujrateDivision.vue"),
  },

  // auth section ()
  {
    path: "/",
    name: "HomeLogin",
    component: () => import("../views/Auth/Login.vue"),
    meta: { headerVisible: "hide" },
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/Login.vue"),
    meta: { headerVisible: "hide" },
  },

  {
    path: "/error-404",
    name: "Error404",
    component: () => import("@/views/Error/Error404.vue"),
    meta: {
      headerVisible: "hide",
    },
  },
  {
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: "/error-404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
