<template>
  <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
    <div class="app-brand demo">
      <router-link to="/home" class="app-brand-link">
        <img style="width: 100%" src="/assets/img/logo/BOW.png" alt="" />
      </router-link>
      <a
        href="javascript:void(0);"
        class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none"
      >
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
      </a>
    </div>
    <hr />

    <div class="menu-inner-shadow"></div>

    <ul class="menu-inner py-1">
      <li class="menu-item" :class="{ active: $route.path === '/home' }">
        <router-link to="/home" class="menu-link">
          <i class="menu-icon fi fi-rr-dashboard"></i>
          <div data-i18n="Dashboards">Dashboards</div>
        </router-link>
      </li>

      <li
        class="menu-item"
        :class="{ active: $route.path === '/register-user-list' }"
      >
        <router-link to="/register-user-list" class="menu-link">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="Dashboards">Registered Customer</div>
        </router-link>
      </li>
      <li
        class="menu-item"
        :class="{ active: $route.path === '/add-new-container' }"
      >
        <router-link to="/add-new-container" class="menu-link">
          <i class="menu-icon fi fi-rr-truck-container"></i>
          <div data-i18n="Port">Add Containers</div>
        </router-link>
      </li>
      <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/container-search' }"
      >
        <router-link to="/container-search" class="menu-link">
          <i class="menu-icon fi fi-rr-search"></i>
          <div data-i18n="Port">Search Container</div>
        </router-link>
      </li>
      <li
        class="menu-item"
        :class="{ active: $route.path === '/active-containers' }"
      >
        <router-link to="/active-containers" class="menu-link">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="Port">Active Containers</div>
        </router-link>
      </li>
      <li
        class="menu-item"
        :class="{ active: $route.path === '/container-under-booking' }"
      >
        <router-link to="/container-under-booking" class="menu-link text-start">
          <i class="menu-icon fi fi-rr-list"></i>
          <div data-i18n="Dashboards">Active Bookings</div>
        </router-link>
      </li>

      <li class="menu-item">
        <span
          class="menu-link text-truncate cursor-pointer active"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="true"
          aria-controls="collapseExample"
          @click="toggleCollapse"
        >
          <i class="menu-icon fi fi-rr-recycle"></i>
          <div data-i18n="Re Use Container">Re Use Container</div>
          <i
            :class="
              isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'
            "
            style="font-size: 12px; right: 10px; position: absolute"
          ></i>
        </span>

        <div class="collapse" id="collapseExample">
          <ul>
            <li class="menu-item text-start">
              <router-link
                to="/reuse-container/add-details"
                class="menu-link cursor-pointer"
              >
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Linking Requests</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link
                to="/reuse-container/delinking-request-list"
                class="menu-link cursor-pointer"
              >
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Delinking Requests</div>
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/payment-pending' }"
      >
        <router-link to="/payment-pending" class="menu-link">
          <i class="menu-icon fi fi-rs-transaction-globe"></i>
          <div data-i18n="Port">Payments Pending</div>
        </router-link>
      </li>
      <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/stats-report' }"
      >
        <router-link to="/stats-report" class="menu-link">
          <!-- <i class="menu-icon fi fi-rs-chart-histogr"></i> -->
          <i class="menu-icon fi fi-rr-chart-histogram"></i>
          <div data-i18n="Port">Stats Report</div>
        </router-link>
      </li>

      <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/notification-list' }"
      >
        <router-link to="/notification-list" class="menu-link">
          <i class="menu-icon fi fi-rr-bell-notification-social-media"></i>

          <div data-i18n="Port">Notification</div>
        </router-link>
      </li>

      <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/history' }"
      >
        <router-link to="/history" class="menu-link">
          <i class="menu-icon fi fi-rr-time-past"></i>

          <div data-i18n="Port">History</div>
        </router-link>
      </li>

      <li class="menu-item">
        <span
          class="menu-link text-truncate cursor-pointer active"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExampleMaster"
          aria-expanded="true"
          aria-controls="collapseExampleMaster"
          @click="toggleCollapse"
        >
          <i class="menu-icon fi fi-rr-apps"></i>
          <div data-i18n="Re Use Container">Master</div>
          <i
            :class="
              isCollapsed ? 'fi fi-rr-angle-right' : 'fi fi-rr-angle-down'
            "
            style="font-size: 12px; right: 10px; position: absolute"
          ></i>
        </span>

        <div class="collapse" id="collapseExampleMaster">
          <ul>
            <li class="menu-item text-start">
              <router-link
                to="/master/axle-list"
                class="menu-link cursor-pointer"
              >
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Truck Axle Weight Category</div>
              </router-link>
            </li>
            <li class="menu-item text-start">
              <router-link
                to="/master/commodity-list"
                class="menu-link cursor-pointer"
              >
                <i class="menu-icon fs-6 fi-rr-dot-circle"></i>
                <div data-i18n="Port">Commodity Type in Container</div>
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- <li
        class="menu-item text-start"
        :class="{ active: $route.path === '/master' }"
      >
        <router-link to="/master" class="menu-link">
          <i class="menu-icon fi fi-rr-grid"></i>
          <div data-i18n="Port">Master</div>
        </router-link>
      </li> -->
      <!-- Master Menu -->
      <!-- <li class="menu-item" :class="{ active: isMenuOpen.master }">
        <a
          href="javascript:void(0);"
          class="menu-link menu-toggle"
          @click="toggleMenu('master')"
        >
          <i class="menu-icon tf-icons bx bx-cog"></i>
          <div data-i18n="Master">Master</div>
        </a>
        <ul class="menu-sub master" :class="{ 'menu-open': isMenuOpen.master }">
          <li
            class="menu-item"
            :class="{ active: $route.path === '/gujrate-division' }"
          >
            <router-link to="/gujrate-division" class="menu-link">
              <div data-i18n="Settings">Gujarat Division</div>
            </router-link>
          </li>

          <li
            class="menu-item"
            :class="{ active: $route.path === 'ocian-carrier' }"
          >
            <router-link to="ocian-carrier" class="menu-link text-start">
              <div data-i18n="Settings">Ocean Carrier Under Contract</div>
            </router-link>
          </li>

          <li
            class="menu-item"
            :class="{ active: $route.path === '/shiping-line' }"
          >
            <router-link to="/shiping-line" class="menu-link">
              <div data-i18n="Settings">Shipping Line</div>
            </router-link>
          </li>

          <li class="menu-item" :class="{ active: $route.path === '/port' }">
            <router-link to="/port" class="menu-link text-start">
              <div data-i18n="Settings">Port</div>
            </router-link>
          </li>

          <li
            class="menu-item"
            :class="{ active: $route.path === '/taluka-list' }"
          >
            <router-link to="/taluka-list" class="menu-link text-start">
              <div data-i18n="Settings">Taluka</div>
            </router-link>
          </li>

          <li
            class="menu-item"
            :class="{ active: $route.path === '/empty-container-list' }"
          >
            <router-link
              to="/empty-container-list"
              class="menu-link text-start"
            >
              <div data-i18n="Settings">Empty Container Handover Location</div>
            </router-link>
          </li>

          <li
            class="menu-item"
            :class="{ active: $route.path === '/commodity-list' }"
          >
            <router-link to="/commodity-list" class="menu-link text-start">
              <div data-i18n="Settings">Commodity Type in Container</div>
            </router-link>
          </li>



          <li
            class="menu-item"
            :class="{ active: $route.path === '/axle-list' }"
          >
            <router-link to="/axle-list" class="menu-link text-start">
              <div data-i18n="Settings">Truck Axle Wight Category</div>
            </router-link>
          </li>
        </ul>
      </li> -->

      <!-- Another Menu -->
      <!-- <li class="menu-item" :class="{ active: isMenuOpen.another }">
        <a
          href="javascript:void(0);"
          class="menu-link menu-toggle"
          @click="toggleMenu('another')"
        >
          <i class="menu-icon tf-icons bx bx-dots-horizontal-rounded"></i>
          <div data-i18n="Another">Another</div>
        </a>
        <ul
          class="menu-sub another"
          :class="{ 'menu-open': isMenuOpen.another }"
        >
          <li class="menu-item" :class="{ active: $route.path === '/item1' }">
            <router-link to="/item1" class="menu-link">
              <div data-i18n="Item 1">Item 1</div>
            </router-link>
          </li>
        </ul>
      </li> -->
    </ul>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      isMenuOpen: {
        master: false,
        another: false,
      },
      isCollapsed: true, // default state for collapse
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    },
    toggleMenu(menu) {
      this.isMenuOpen[menu] = !this.isMenuOpen[menu];
      this.updateMenuState(menu);
    },
    updateMenuState(menu) {
      const menuSub = this.$el.querySelector(`.menu-sub.${menu}`);
      if (menuSub) {
        if (this.isMenuOpen[menu]) {
          menuSub.style.display = "block";
        } else {
          menuSub.style.display = "none";
        }
      }
    },
  },
  mounted() {
    Object.keys(this.isMenuOpen).forEach((menu) => {
      this.updateMenuState(menu);
    });
  },
};
</script>
<style scoped>
.menu-sub {
  max-height: 0;
  overflow: hidden;
  transition: max-height 2s ease-in-out;
}

.menu-sub.menu-open {
  max-height: 500px;
  /* Set a value that is larger than the expected height of the submenu */
}
</style>
